export const environment = {
    appname: "aTimeRecording (TEST)",
    production: true,
    serviceWorker: true,
    build: "test",
    version: "1.4.19",
    keycloak: {
        issuer: "https://keycloak.activity-time-recording.com/",
        realm: "timetracking_development",
        clientId: "timetracking.pwa.test"
    },
    api: {
        baseUrl: "https://test.api.activity-time-recording.com",
    },
    glitchTip: {
        dsn: "https://c8f5363a52244128bf774104fa493e93@glitchtip.activity-time-recording.com/3"
    },
}
